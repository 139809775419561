import React from 'react'
import { Tag } from 'antd'

export default function Status({ status }) {
  if (status === 0) {
    return (<Tag color="cyan" >สั่งซื้อ</Tag>)
  } else if (status === 1) {
    return (<Tag color="orange" >ตรวจสอบการชำระเงิน</Tag>)
  } else if (status === 2) {
    return (<Tag color="lime" >ชำระเงินถูกต้อง</Tag>)
  } else if (status === 3) {
    return (<Tag color="gold" >อยู่ระหว่างจัดส่ง</Tag>)
  } else if (status === 4) {
    return (<Tag color="green" >ทำรายการสำเร็จ</Tag>)
  } else if (status === 100) {
    return (<Tag color="red" >ยกเลิกออเดอร์</Tag>)
  }
}
