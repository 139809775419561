
export const SUB_URL = "//www.dev.onnetthai.com/";
export const BASE_URL = "//www.dev.onnetthai.com/";
//export const RUN_URL = "http://localhost:5000/api/"
export const RUN_URL = "//www.run.onnetthai.com/api/"
// export const SUB_URL = "http://www.gebtam.com/";
// export const BASE_URL = "http://www.gebtam.com/";
//export const BASE_URL = "http://localhost:5555/";
export const APP_URL = "appi/v1/members/";
export const APP_URL_V12 = "appi/v1.2/members/";
export const ORDER_URL = "order/v1/members/";