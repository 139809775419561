import React, { Component } from 'react'
import { Layout } from 'antd'
import AdminLayoutHeader from './AdminLayoutHeader';
import Container from '../Container';


const { Content } = Layout;
export default class AdminBaseLayout extends Component {
  render() {
    return (
      <Layout className="min-100">
        <AdminLayoutHeader />
        <Content >
          <Container>
            {this.props.children}
          </Container>
        </Content>
      </Layout>
    )
  }
}
