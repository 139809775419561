import http from "./HttpService";
import { message } from "antd";
import { runRequestPost } from "../helpers/fetch";

class Sell {

  async sendOtp(data) {
    try {
      const resp = await runRequestPost(`otp`, data)
      if (resp.status === 201) {
        message.success('ส่ง opt สำเร็จ')
      } else {
        message.error('เกิดข้อผิดพลาด')
      }
      return
    } catch (error) {
      console.log(error);
      return message.error('เกิดข้อผิดพลาด')
    }
  }

  async checkOtp(data) {
    try {
      const resp = await runRequestPost(`otp/checkotp`, data)
      if (resp.status === 200) {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log(error);
      return message.error('เกิดข้อผิดพลาด')
    }
  }

  async getUser(tel) {
    try {
      return await http.requestGet(`users/${tel}`);
    } catch (error) {
      console.log(error);
      return message.error('เกิดข้อผิดพลาด')
    }
  }

  async createOrder(data) {
    try {
      return await http.runRequestPost('order', data);
    } catch (error) {
      console.log(error);
      return message.error('เกิดข้อผิดพลาด')
    }
  }

  async getOrders(data) {
    try {

      return await http.runRequestPost('order/getorder', data);
    } catch (error) {
      console.log(error);
      return message.error('เกิดข้อผิดพลาด')
    }
  }

  async uploadImage(files) {
    try {
      return await http.runRequestPost('', files);
    } catch (error) {
      console.log(error);
      return message.error('เกิดข้อผิดพลาด')
    }
  }

  async setImagePath(data) {
    try {
      const resp = await http.runRequestPost('order/setimage', data);
      message.success('ทำรายการสำเร็จ');
      return resp;
    } catch (error) {
      console.log(error);
      return message.error('เกิดข้อผิดพลาด')
    }
  }

  async cancelOrder(data) {
    try {
      const resp = await http.runRequestPost('order/cancelOrder', data);
      message.success('ยกเลิกรายการเรียบร้อย');
      return resp;
    } catch (error) {
      console.log(error);
      return message.error('เกิดข้อผิดพลาด')
    }
  }

}
const SellService = new Sell();
export default SellService;