import React, { Component } from 'react'
import { Spin, Button, Col, Row } from 'antd';
import AdminBaseLayout from '../../components/layout/admin/AdminBaseLayout'
import AdminService from '../../services/AdminService'
import OrderList from '../../components/admin/OrderList';
import OrderRangePicker from '../../components/admin/OrderRangePicker';
import { headers, orderInfer } from '../../helpers/constant';
import { CSVLink } from 'react-csv';
import { setThaiDate } from '../../helpers/time';

export default class AdminOrderSending extends Component {

  state = {
    orderData: [],
    isLoading: true,
    startDate: null,
    endDate: null,
    data: []
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData(startDate, endDate) {
    try {

      this.setState({ isLoading: true })
      const data = [];
      const orderinfer = JSON.parse(JSON.stringify(orderInfer));
      const resp = await AdminService.getOrders('3', startDate, endDate);
      const orderData = resp.map(order => {
        order.name = order.user.name;
        order.lastname = order.user.lastname;
        order.telNumber = order.user.telNumber;
        order.ordersList.forEach((list, i) => {
          orderinfer[list.color][list.size] += list.amount;
          const newOrder = { ...order };
          newOrder.status = "อยู่ระหว่างจัดส่ง";
          newOrder.color = list.color;
          newOrder.amount = list.amount;
          newOrder.size = list.size;
          newOrder.telNumber = `'${newOrder.telNumber}'`
          newOrder.address = newOrder.address.replace(/\n/g, '');
          newOrder.updated = setThaiDate(newOrder.updated);
          newOrder.byId = newOrder.id;
          newOrder.detail = `${newOrder.name} ${newOrder.lastname}  ${order.telNumber}   ${newOrder.address}`
            .replace(/\n/, '')
          if (i !== 0) {
            newOrder.name = '';
            newOrder.lastname = '';
            newOrder.address = ''
            newOrder.telNumber = '';
            newOrder.id = ''
            newOrder.updated = ''
            newOrder.detail = ''
          }

          data.push(newOrder);
        })
        return order
      })
      data.sort((a, b) => a.byId - b.byId)
      data.unshift({ 'allColor': 'ขาว', ...orderinfer['ขาว'] },
        { 'allColor': 'น้ำเงิน', ...orderinfer['น้ำเงิน'] },
        { 'allColor': 'ดำ', ...orderinfer['ดำ'] })
      this.setState({ orderData, data, isLoading: false })
      return data
    } catch (error) {
      this.setState({ isLoading: false })
      console.log(error)
    }
  }

  rangePickerChange(startDate, endDate) {
    if (startDate === "Invalid date" || endDate === "Invalid date") {
      startDate = '';
      endDate = ''
    }
    this.setState({ startDate, endDate });
    this.fetchData(startDate, endDate);
  }

  setOrder(order) {
    const { orderData } = this.state;
    const index = orderData.findIndex(item => item.id === order.id);
    if (index !== -1) {
      orderData[index] = order;
      this.setState({ orderData })
    }
  }

  render() {
    const { orderData, isLoading, data } = this.state;
    return (
      <AdminBaseLayout >
        <Row type="flex" justify="space-between" className="mt-3" align="bottom">
          <Col>
            <OrderRangePicker rangePickerChange={this.rangePickerChange.bind(this)} />
          </Col>
          {!isLoading && <Col>
            <CSVLink
              data={data}
              headers={headers}
              filename={`อยู่ระหว่างส่ง-${Date.now()}.csv`}
            >
              <Button type="primary">EXPORT</Button>
            </CSVLink>
          </Col>}

        </Row>
        <div className="mt-3">
          {isLoading ? <Spin tip="Loading..." >
            <div style={{ minHeight: '80vh' }}></div>
          </Spin> : <OrderList title="ออเดอร์ที่กำลังส่ง"
            setOrder={this.setOrder.bind(this)}
            orderData={orderData} />}
        </div>
      </AdminBaseLayout>
    )
  }
}