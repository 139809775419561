import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import thTH from 'antd/lib/locale-provider/th_TH';
import moment from 'moment';
import 'moment/locale/th';
import { ConfigProvider } from 'antd';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import initialState from './store';

const store = initialState({});
moment.locale('th');

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={thTH}>
      <HashRouter>
        <App />
      </HashRouter>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
