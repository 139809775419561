import { adminAuthTypes } from './adminAuthTypes'


export const adminAuthRequest = () => ({ type: adminAuthTypes.REQUEST });

export const adminAuthSuccess = result => ({ type: adminAuthTypes.SUCCESS, payload: result });

export const adminAuthError = errors => ({ type: adminAuthTypes.ERROR, errors });

export const adminProfileRequest = () => ({ type: adminAuthTypes.PROFILE_REQUEST });

export const adminProfileSuccess = profile => ({ type: adminAuthTypes.PROFILE_SUCCESS, payload: profile });

export const adminProfileError = errors => ({ type: adminAuthTypes.PROFILE_ERROR, errors });

export const adminGoLogout = () => ({ type: adminAuthTypes.LOGOUT });
