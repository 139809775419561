import { RUN_URL } from "../config";

export const runRequestPost = async (url = '', data = {}, token = '') => {
  try {
    const response = await fetch(`${RUN_URL}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
}

export const runRequestGet = async (url = '', token = '') => {
  try {
    const response = await fetch(`${RUN_URL}${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const runRequestPatch = async (url = '', data = {}, token = '') => {
  try {
    const response = await fetch(`${RUN_URL}${url}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    return response;
  } catch (error) {
    throw error;
  }
}