import React, { Component } from 'react'
import { Spin, Row, Col, Button } from 'antd';
import { CSVLink } from "react-csv";
import AdminBaseLayout from '../../components/layout/admin/AdminBaseLayout'
import AdminService from '../../services/AdminService'
import OrderList from '../../components/admin/OrderList';
import OrderRangePicker from '../../components/admin/OrderRangePicker';
import { headers, orderInfer } from '../../helpers/constant';
import { setThaiDateOnly, setThaiDate } from '../../helpers/time';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { MyDocument } from '../../components/admin/mypdf/MyDocument';


export default class AdminOrderVerifySuccess extends Component {

  state = {
    orderData: [],
    isLoading: true,
    startDate: '',
    endDate: '',
    data: [],
    isShowPdf: false,
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData(startDate, endDate) {
    try {
      this.setState({ isLoading: true, isShowPdf: false })
      const data = [];
      const orderinfer = JSON.parse(JSON.stringify(orderInfer));
      const resp = await AdminService.getOrders('2', startDate, endDate);
      const orderData = resp.map(order => {
        order.name = order.user.name;
        order.lastname = order.user.lastname;
        order.telNumber = order.user.telNumber;
        order.ordersList.forEach((list, i) => {
          orderinfer[list.color][list.size] += list.amount;
          const newOrder = { ...order };
          newOrder.status = "ชำระเงินถูกต้อง";
          newOrder.color = list.color;
          newOrder.amount = list.amount;
          newOrder.size = list.size;
          newOrder.telNumber = `'${newOrder.telNumber}'`
          newOrder.address = newOrder.address.replace(/\n/g, '');
          newOrder.updated = setThaiDate(newOrder.updated);
          newOrder.byId = newOrder.id;
          newOrder.detail = `${newOrder.name} ${newOrder.lastname}  ${order.telNumber}   ${newOrder.address}`
            .replace(/\n/, '')
          for (let j = 0; j < 9; j++) {
            newOrder[`z${j}`] = `z${j}`;
          }
          if (i !== 0) {
            newOrder.name = '';
            newOrder.lastname = '';
            newOrder.address = ''
            newOrder.telNumber = '';
            newOrder.id = ''
            newOrder.updated = ''
            newOrder.detail = ''
          }

          data.push(newOrder);
        })
        return order
      })
      data.sort((a, b) => a.byId - b.byId)
      data.unshift({ 'allColor': 'ขาว', ...orderinfer['ขาว'] },
        { 'allColor': 'น้ำเงิน', ...orderinfer['น้ำเงิน'] },
        { 'allColor': 'ดำ', ...orderinfer['ดำ'] })
      this.setState({ orderData, data, isLoading: false, startDate, endDate })
      return data
    } catch (error) {
      this.setState({ isLoading: false })
      console.log(error)
    }
  }

  async rangePickerChange(startDate, endDate) {
    if (startDate === "Invalid date" || endDate === "Invalid date") {
      startDate = '';
      endDate = ''
    }
    //  this.setState({ startDate, endDate });
    await this.fetchData(startDate, endDate);
  }


  setOrder(order) {
    const { orderData } = this.state;
    const index = orderData.findIndex(item => item.id === order.id);
    if (index !== -1) {
      orderData[index] = order;
      this.setState({ orderData })
    }
  }


  render() {
    const { orderData, isLoading, data, startDate, endDate, isShowPdf } = this.state;
    return (
      <AdminBaseLayout >
        <Row type="flex" justify="space-between" className="mt-3" align="bottom">
          <Col>
            <OrderRangePicker rangePickerChange={this.rangePickerChange.bind(this)} />
          </Col>
          {!isLoading && <Col>
            <CSVLink
              data={data}
              headers={headers}
              filename={`ชำระเงินถูกต้อง-${Date.now()}.csv`}
            >
              <Button type="primary">EXPORT</Button>
            </CSVLink>
          </Col>}

        </Row>
        <div className="mt-3">
          {isLoading ? <Spin tip="Loading..." >
            <div style={{ minHeight: '80vh' }}></div>
          </Spin> : <OrderList title="ออเดอร์ที่ตรวจสอบความถูกต้องแล้ว"
            setOrder={this.setOrder.bind(this)}
            orderData={orderData} />}
        </div>
        <div className="mt-3">
          {isLoading ? <Spin tip="Loading..." >
            <div style={{ minHeight: '80vh' }}></div>
          </Spin> : <>
              <div className="mt-3 mb-3 text-center">
                <Button type="ghost"
                  onClick={() => this.setState({ isShowPdf: true })} >
                  แสดง pdf
                 </Button>
              </div>
              {isShowPdf && <PDFViewer style={{ width: '100%', minHeight: '100vh' }}>
                <MyDocument data={orderData.sort((a, b) => a.id - b.id).slice(0, 500)}
                  startDate={setThaiDateOnly(startDate)}
                  endDate={setThaiDateOnly(endDate)} />
              </PDFViewer>}
            </>

          }
          {/* {!isLoading && <PDFDownloadLink
            document={<MyDocument data={orderData.slice(0, 10)} />}
            fileName={`${__dirname}/example.pdf`}
            style={{
              textDecoration: "none",
              padding: "10px",
              color: "#4a4a4a",
              backgroundColor: "#f2f2f2",
              border: "1px solid #4a4a4a"
            }}
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download Pdf"
            }
          </PDFDownloadLink>} */}
        </div>

      </AdminBaseLayout>
    )
  }
}