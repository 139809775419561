import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';

// Create styles


const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 0,
    fontFamily: 'thai',
    position: 'relative',
    fontSize: 14,
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    fontFamily: 'thai'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 14,
    margin: 12,
    fontFamily: 'thai'
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'thai'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  row: {
    position: 'relative',
    flexWrap: 'wrap',
    flexDirection: 'row',
    // marginBottom: 5
  },
  borderNoRight: {
    borderLeft: 1,
    borderTop: 1,
    borderBottom: 1,
    borderStyle: 'solid',
    borderColor: 'gray',
    paddingLeft: 2,
    paddingRight: 2
  },
  borderNoLeft: {
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1,
    borderStyle: 'solid',
    borderColor: 'gray',
    paddingLeft: 2,
    paddingRight: 2
  },
  borderNoTop: {
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderStyle: 'solid',
    borderColor: 'gray',
    paddingLeft: 2,
    paddingRight: 2
  },
  borderNoBottom: {
    borderLeft: 1,
    borderTop: 1,
    borderRight: 1,
    borderStyle: 'solid',
    borderColor: 'gray', paddingLeft: 2,
    paddingRight: 2
  },
  border: {
    border: 1,
    borderStyle: 'solid',
    borderColor: 'gray'
  }

});
// Create Document Component
export const MyDocument = ({ data, startDate, endDate }) => {
  console.log(data);
  // const data = new Array(20).fill(0).map((_, i) => i + 1)
  return (<Document>
    <Page style={styles.body}>
      <Text style={styles.header} fixed render={({ pageNumber, totalPages }) => (
        `ออเดอร์ตั้งแต่วันที่ ${startDate} - ${endDate}   ...หน้า ${pageNumber}`
      )}>
      </Text>
      {/* <Text style={styles.author}>Miguel de Cervantes</Text> */}
      {data.map(order => {
        return (
          <View wrap={false} key={order.id} style={{ ...styles.row }}>
            <View wrap={false} style={{ ...styles.row, width: '50%' }}>
              <View wrap={false} style={{
                ...styles.borderNoRight,
                paddingBottom: 0,
                borderBottom: 0,
                width: '65%',
                maxWidth: '65%'
              }}>
                <View>
                  <Text >
                    เลขออเดอร์ {order.id}
                  </Text>
                  <Text >
                    ชื่อ {order.user.name} {order.user.lastname}
                  </Text>
                  <Text >
                    โทร {order.user.telNumber}
                  </Text>
                  <Text >
                    ที่อยู่  {order.address.replace(/\n/g, '  ')}
                  </Text>
                </View>
              </View>
              <View wrap={false} style={{
                ...styles.border,
                borderBottom: 0,
                width: '35%',
                maxWidth: '35%',
                display: 'flex'
              }}>
                <Text style={{
                  textAlign: 'center',
                  marginTop: 'auto',
                  marginBottom: 'auto'
                }} >
                </Text>
              </View>
              <View wrap={false} style={{
                ...styles.borderNoRight,
                borderTop: 0,
                paddingTop: 0,
                width: '65%',
                maxWidth: '65%'
              }}>
                <View>
                  {order.ordersList.map(list => {
                    return (<Text key={list.id}>{list.color}</Text>)
                  })}
                </View>
              </View>
              <View wrap={false} style={{
                ...styles.border,
                borderTop: 0,
                width: '35%',
                maxWidth: '35%',
                textAlign: 'right'
              }}>

                {order.ordersList.map(list => {
                  return (<View key={list.id} style={{ ...styles.row, width: '100%' }}>
                    <View style={{ width: '50%' }}>
                      <Text>{list.size}</Text>
                    </View>
                    <View style={{ width: '50%' }}>
                      <Text>{list.amount}</Text>
                    </View>
                  </View>)
                })}
              </View>
            </View>
            <View wrap={false} style={{ ...styles.row, width: '50%' }}>
              <View wrap={false} style={{
                ...styles.borderNoRight,
                paddingBottom: 0,
                borderBottom: 0,
                width: '65%',
                maxWidth: '65%'
              }}>
                <View>
                  <Text >
                    เลขออเดอร์ {order.id}
                  </Text>
                  <Text >
                    ชื่อ {order.user.name} {order.user.lastname}
                  </Text>
                  <Text >
                    โทร {order.user.telNumber}
                  </Text>
                  <Text >
                    ที่อยู่  {order.address.replace(/\n/g, '  ')}
                  </Text>
                </View>
              </View>
              <View wrap={false} style={{
                ...styles.border,
                borderBottom: 0,
                width: '35%',
                maxWidth: '35%',
                display: 'flex'
              }}>
                <Text style={{
                  textAlign: 'center',
                  marginTop: 'auto',
                  marginBottom: 'auto'
                }} >
                </Text>
              </View>
              <View wrap={false} style={{
                ...styles.borderNoRight,
                borderTop: 0,
                paddingTop: 0,
                width: '65%',
                maxWidth: '65%'
              }}>
                <View>
                  {order.ordersList.map(list => {
                    return (<Text key={list.id}>{list.color}</Text>)
                  })}
                </View>
              </View>
              <View wrap={false} style={{
                ...styles.border,
                borderTop: 0,
                width: '35%',
                maxWidth: '35%',
                textAlign: 'right'
              }}>

                {order.ordersList.map(list => {
                  return (<View key={list.id} style={{ ...styles.row, width: '100%' }}>
                    <View style={{ width: '50%' }}>
                      <Text>{list.size}</Text>
                    </View>
                    <View style={{ width: '50%' }}>
                      <Text>{list.amount}</Text>
                    </View>
                  </View>)
                })}
              </View>
            </View>
          </View>)
      })}
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
  </Document>)
}
