import React, { Component } from 'react'
import SellService from '../../services/SellService'
import Order from './product-card/Order';
import { Row, Col, Skeleton, Card } from 'antd';

export default class ProductHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      orders: []
    }
  }
  async  componentDidMount() {
    const data = { telNumber: this.props.telNumber };
    const resp = await SellService.getOrders(data)
    this.setState({ orders: resp, isLoading: false, });
  }

  onUpdate() {
    this.props.onUpdate();
  }

  renderOrder() {
    const { orders } = this.state;
    if (orders.length > 0) {
      return orders.map((order, i) => {
        return (
          <Col key={i} xs={24} sm={24} md={18} lg={12} span={12}>
            <Order order={order} update={this.onUpdate.bind(this)} />
          </Col>
        )
      })
    } else {
      return (
        <Card>
          <p>ไม่พบประวัติ</p>
        </Card>
      )
    }

  }

  render() {
    const { isLoading } = this.state;
    return (
      <Row type="flex" justify="center" gutter={[12, 12]}>
        {isLoading ? <Skeleton /> : this.renderOrder()}
      </Row>
    )
  }
}
