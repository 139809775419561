import React, { Component } from 'react'
import { Table, Input, Button, Icon, Skeleton } from 'antd';
import Highlighter from 'react-highlight-words';
import AppService from '../../services/AppService';



export default class TableList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      searchedColumn: '',
      data: []
    };
  }

  componentWillMount() {
    AppService.getAllShop().then(resp => {
      const newData = resp.map((data, i) => {
        data.num = i + 1;
        let typeRun = '';
        if (data.m_type_run === '1') {
          typeRun = '5 Km.'
          if (data.m_srn_check === '1') {
            if (data.m_srn_5 === 'T' || data.m_srn_10 === 'T') {
              typeRun = '5 Km./เด็กสุระ'
            } else {
              typeRun = '5 Km/Suranaree'
            }
          }
        } else if (data.m_type_run === '2') {
          typeRun = '10 Km.'
          if (data.m_srn_check === '1') {
            if (data.m_srn_5 === 'T' || data.m_srn_10 === 'T') {
              typeRun = '10 Km./เด็กสุระ'
            } else {
              typeRun = '10 Km./Suranaree'
            }
          }
        } else if (data.m_type_run === '3') {
          typeRun = '5 Km.(VIP)'
          if (data.m_srn_check === '1') {
            if (data.m_srn_5 === 'T') {
              typeRun = '5 Km.(VIP)/เด็กสุระ'
            } else {
              typeRun = '5 Km.(VIP)/Suranaree'
            }
            if (data.m_srn_10 === 'T') {
              typeRun = typeRun + '/เด็กสุระ'
            } else {
              typeRun = typeRun + '/Suranaree'
            }
          }
        } else if (data.m_type_run === '4') {
          typeRun = '10 Km.(VIP)'
          if (data.m_srn_check === '1') {
            if (data.m_srn_5 === 'T') {
              typeRun = '10 Km.(VIP)/เด็กสุระ'
            } else {
              typeRun = '10 Km.(VIP)/Suranaree'
            }
            if (data.m_srn_10 === 'T') {
              typeRun = typeRun + '/เด็กสุระ'
            } else {
              typeRun = typeRun + '/Suranaree'
            }
          }
        }

        data.typeRunText = typeRun;
        return data;
      })
      this.setState({ ...this.state, data: newData })

    }).catch(error => {
      console.log(error);
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`ค้นหา ${dataIndex === 'm_name' ? 'ชื่อ' :
            dataIndex === 'typeRunText' ? 'ประเภท' : 'นามสกุล'}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}

          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          <Icon type="search" style={{}} />
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search"
        style={{ color: filtered ? '#1890ff' : undefined, fontSize: 20 }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const { data } = this.state;
    const columns = [
      {
        title: 'ลำดับที่',
        dataIndex: 'num',
        key: 'num',
        width: 80,
        align: 'center',
        ellipsis: true
      },
      {
        title: 'ชื่อ',
        dataIndex: 'm_name',
        key: 'm_name',
        width: 150,
        ...this.getColumnSearchProps('m_name'),
        ellipsis: true
      },
      {
        title: 'นามสกุล',
        dataIndex: 'm_lastname',
        key: 'm_lastname',
        width: 150,
        ...this.getColumnSearchProps('m_lastname'),
        ellipsis: true
      }, {
        title: 'ไซต์เสื้อ',
        dataIndex: 'm_size',
        key: 'm_size',
        align: 'center',
        width: 80,
        ellipsis: true
      }, {
        title: 'ระยะ/ประเภท',
        dataIndex: 'typeRunText',
        key: 'typeRunText',
        align: 'center',
        width: 100,
        ellipsis: true,
        ...this.getColumnSearchProps('typeRunText'),
      },
    ];
    if (data.lenght === 0) {
      return <Skeleton />

    } else {
      return <Table bordered columns={columns} pagination={{ defaultPageSize: 20 }} dataSource={data} />;
    }

  }
}
