import { BASE_URL, RUN_URL } from "../config";
// import AppService from "./AppService";

const http = {
  async requestPost(url = '', data = {}) {
    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrer: 'no-referrer',

      body: data
    });
    return response.json();
  },
  async runRequestPost(url = '', data = {}) {
    const response = await fetch(`${RUN_URL}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return response.json();
  },
  async runRequestPut(url = '', data = {}) {
    const response = await fetch(`${RUN_URL}${url}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return response.json();
  },
  async runUploadRequestPost(url = '', data = {}) {
    const response = await fetch(`${RUN_URL}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return response.json();
  },
  async requestGet(url = '') {
    const response = await fetch(`${RUN_URL}${url}`, {
      method: 'GET'
    })
    return response.json();
  }
}

export default http;