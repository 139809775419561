import React, { Component } from 'react'
import { Card, Button, Col, Row, Modal, Avatar, Upload, Icon, Divider, Popconfirm, Tag } from 'antd'
import { RUN_URL } from '../../../config';
import SellService from '../../../services/SellService';


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default class Order extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isCheckPayment: false,
      isPayment: false,
      previewVisible: false,
      previewImage: '',
      uploading: false,
      fileList: []
    }
  }

  async onCancelOrder() {
    const { order } = this.props;
    const postData = { id: order.id }
    await SellService.cancelOrder(postData);
    this.props.update();
  }

  renderButtonCancel() {
    return (
      <Popconfirm placement="topLeft"
        okType="danger"
        icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
        title="ยกเลิกรายการสั่งซื้อ?"
        onConfirm={() => this.onCancelOrder()}
        okText="ใช่"
        cancelText="ไม่">
        <Button type="danger"
          className="mb-2"
          onClick={() => null}
        >ยกเลิกรายการ
    </Button>
      </Popconfirm>
    )
  }

  renderOrderStatus() {
    const { order } = this.props;
    if (order.slipStatus === 0) {
      return (
        <Row type="flex" justify="space-around">
          {this.renderButtonCancel()}
          <Button type="primary"
            onClick={() => this.setState({ isPayment: true, isCheckPayment: false })}
          >ชำระเงิน</Button>
        </Row>
      )
    } else if (order.slipStatus === 1) {
      return (
        <Row type="flex" justify="space-around">
          {this.renderButtonCancel()}
          <Button type="dashed"
            onClick={() => this.setState({ isCheckPayment: true, isPayment: false })}
            className="warn" >อยู่ระหว่างตรวจสอบการชำระเงิน
       </Button>
        </Row>
      )
    } else if (order.slipStatus === 2) {
      return (
        <Row type="flex" justify="center">
          <Tag color="lime" >ชำระเงินถูกต้อง</Tag>
        </Row>
      )
    } else if (order.slipStatus === 3) {
      return (
        <Row type="flex" justify="center" align="middle">
          <Tag color="gold" >อยู่ระหว่างจัดส่ง <br /> หมายเลขพัสดุ: {order.tagId}</Tag>
          <a href={`https://th.kerryexpress.com/th/track/?track=${order.tagId}`}
            target="_black"
          >
            ตรวจสอบพัสดุ</a>
        </Row>
      )
    } else if (order.slipStatus === 4) {
      return (
        <Row type="flex" justify="center" align="middle">
          <Tag color="green" >ทำรายการสำเร็จ</Tag>
        </Row>
      )
    } else if (order.slipStatus === 100) {
      return (
        <Row type="flex" justify="center">
          <Button type="ghost" disabled >รายการถูกยกเลิกแล้ว</Button>
        </Row>
      )
    }
  }

  renderImage() {
    const { order } = this.props;
    return (
      <Row type="flex" justify="center" className="mb-3" gutter={[8, 8]}>
        {order.images.map((image, i) => {
          return (
            <Col key={i} span={6}>
              <Avatar shape="square" style={{ width: '100%', height: '6rem' }} src={image.imagePath} />
            </Col>)
        })}
      </Row>
    )
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList, event }) => {
    if (event) {
      this.setState({ uploading: true })
    } else {
      this.setState({ uploading: false })
    }

    this.setState({ fileList });
  }

  async handleOk() {
    // {
    //   "id": 2,
    //     "imagePath": ["0000","9"]
    // }
    const { order } = this.props;
    const imagePath = [];
    const { fileList } = this.state;
    fileList.forEach(file => {
      imagePath.push(file.response[0].location);
    })
    const postData = { id: order.id, imagePath }
    await SellService.setImagePath(postData);
    this.props.update();
  }

  renderListOrder(orders) {
    if (orders.ordersList) {
      return orders.ordersList.map((value, i) => {
        return (
          <div key={i}>
            <Row type="flex" justify="space-between">
              <Col>
                <h4>สีเสื้อ: </h4>
              </Col>
              <Col>
                <h4>{value.color} </h4>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col>
                <h4>ขนาดเสื้อ: </h4>
              </Col>
              <Col>
                <h4>{value.size}</h4>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col>
                <h4>จำนวน:</h4>
              </Col>
              <Col>
                <h4> {value.amount} ตัว</h4>
              </Col>
            </Row>
            <Divider type="horizontal" />
          </div>
        )
      })
    }
  }

  renderPayment() {
    return (
      <Card className="mb-3">
        <p className="size-10 bold">ช่องทางการชำระเงิน</p>
        <div className="mb-2">
          {/* <Avatar shape="square" size={30} src="./morecre.PNG" /> */}
          <Avatar shape="square" style={{ width: 60, height: 60 }} src="./tmb.jpg" />
        </div>
        <span className="size-10">ชื่อบัญชี : KORAT STRONG เพื่อช่วยเหลือผู้ประสบเหตุ เทอร์มินอล 21 หรือ  "นายรวีวงศ์​ สุภาพวนิช" ผจก.ธนาคารทหารไทย</span>
        <p>ธนาคารทหารไทย (TMB)</p>
        <span className="size-12">เลขที่บัญชี 480-2-67884-9</span>
      </Card>
    )
  }

  render() {
    const { order } = this.props;
    const { previewVisible, previewImage, fileList, uploading } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <React.Fragment>
        <Modal
          title="ข้อมูลสลิป"
          visible={this.state.isCheckPayment}
          onOk={() => this.handleOk()}
          onCancel={() => this.setState({ isCheckPayment: false })}
          footer={[
            <Button key="back" onClick={() => this.setState({ isCheckPayment: false })}>
              ปิด
            </Button>,
            <Button
              disabled={fileList.length === 0 || uploading}
              key="submit"
              type="primary"
              onClick={() => this.handleOk()}>
              บันทึก
            </Button>
          ]}
        >
          {this.renderPayment()}
          {this.renderImage()}
          <Upload
            action={`${RUN_URL}image-upload`}
            name="upload"
            multiple
            accept="image/*"
            listType="picture-card"
            onPreview={this.handlePreview}
            onChange={this.handleChange}

            className="text-center"
          >
            {(order.images.length + fileList.length) >= 5 ? null : uploadButton}
          </Upload>
        </Modal>


        <Modal
          title="แนบสลิป"
          visible={this.state.isPayment}
          onOk={() => this.handleOk()}
          onCancel={() => this.setState({ isPayment: false })}
          footer={[
            <Button key="back" onClick={() => this.setState({ isPayment: false })}>
              ปิด
            </Button>,
            <Button disabled={fileList.length === 0 || uploading}
              key="submit"
              type="primary" onClick={() => this.handleOk()}>
              บันทึก
            </Button>,
          ]}
        >
          {this.renderPayment()}
          <p>แนบสลิปจ่ายเงิน</p>
          <Upload
            action={`${RUN_URL}image-upload`}
            name="upload"
            multiple
            accept="image/*"
            listType="picture-card"
            onPreview={this.handlePreview}
            onDownload={false}
            onChange={this.handleChange}
            className="text-center"
          >
            {(order.images.length + fileList.length) >= 5 ? null : uploadButton}
          </Upload>
        </Modal>
        <div className="clearfix">

          <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </div>



        <Card hoverable={order.slipStatus !== 100} style={{ width: '100%', opacity: order.slipStatus === 100 ? .5 : 1 }} >
          <div>
            {this.renderListOrder(order)}
            <Row type="flex" justify="space-between">
              <Col>
                <h4>ค่าเสื้อ </h4>
              </Col>
              <Col>
                <h4>{order.shirtCost} บาท</h4>
              </Col>
            </Row>
            {order.typeResive === '2' &&
              <Row type="flex" justify="space-between">
                <Col>
                  <h4>ค่าจัดส่ง </h4>
                </Col>
                <Col>
                  <h4>{order.shippingCost} บาท</h4>
                </Col>
              </Row>}
            <Row type="flex" justify="space-between">
              <Col>
                <h4>รวมเป็นเงิน </h4>
              </Col>
              <Col>
                <h4>{order.total} บาท</h4>
              </Col>
            </Row>
          </div>
          {
            order.typeResive === '1' ? <Card.Meta title="รับเอง" /> :
              <Card.Meta title="ที่อยู่" description={order.address} />
          }

          <Divider type="horizontal" />
          {this.renderOrderStatus()}
        </Card >
      </React.Fragment>
    )
  }
}
