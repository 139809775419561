import React, { Component } from 'react'
import { Tabs } from 'antd';
import SelectProduct from './SelectProduct';
import ProductHistory from './ProductHistory';

import '../../styles/product.css'

const { TabPane } = Tabs;



export default class ProductHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabs: '1'
    }
  }

  nextTab() {
    this.setState({ tabs: '2' })
  }

  setTab(e) {
    this.setState({ tabs: e })
  }

  onUpdate() {
    this.setState({ tabs: '1' })
    setTimeout(() => {
      this.setState({ tabs: '2' })
    }, 100)
  }
  render() {
    const { tabs } = this.state;
    const { telNumber } = this.props;
    return (
      <Tabs
        activeKey={tabs}
        onTabClick={(e) => this.setTab(e)}
        className="w-100"
        tabBarStyle={{ marginLeft: 'auto' }}
      >
        <TabPane tab="สั่งซื้อเสื้อ" className="pt-3 pb-3" key="1">
          {tabs === '1' ? <SelectProduct
            telNumber={telNumber}
            nextTab={this.nextTab.bind(this)}
            goHome={this.props.homeProduct}
          /> : ''}
        </TabPane>
        <TabPane tab="สถานะการซื้อ" className="pt-3 pb-3 min-70" key="2">

          {tabs === '2' ? <ProductHistory
            telNumber={telNumber}
            onUpdate={this.onUpdate.bind(this)}
          /> : ''}
        </TabPane>
      </Tabs>
    )
  }
}
