export const headers = [
  //{ label: "z", key: "z0" },
  { label: "ออเดอร์ไอดี", key: "id" },
  //{ label: "z", key: "z1" },
  { label: "ชื่อ", key: "name" },
  //{ label: "z", key: "z2" },
  { label: "นามสกุล", key: "lastname" },
  //{ label: "z", key: "z3" },
  { label: "หมายเลขโทรศัพท์", key: "telNumber" },
  //{ label: "z", key: "z4" },
  { label: "ที่อยู่", key: "address" },
  //{ label: "จัดส่ง", key: "detail" },
  // { label: "ราคารวม", key: "total" },
  // { label: "ราคาเสื้อรวม", key: "shirtCost" },
  // { label: "ค่าจัดส่งรวม", key: "shippingCost" },
  //{ label: "สถานะ", key: "status" },
  //{ label: "z", key: "z5" },
  { label: "สีเสื้อ", key: "color" },
  //{ label: "z", key: "z6" },
  { label: "ขนาดเสื้อ", key: "size" },
  //{ label: "z", key: "z7" },
  { label: "จำนวนเสื้อ", key: "amount" },
  //{ label: "z", key: "z8" },
  { label: "เวลาเปลี่ยนสถานะ", key: "updated" },
  //{ label: "z", key: "z9" },
  { label: "สีเสื้อ", key: "allColor" },
  { label: "S (ตัว)", key: "S" },
  { label: "M (ตัว)", key: "M" },
  { label: "L (ตัว)", key: "L" },
  { label: "XL (ตัว)", key: "XL" },
  { label: "2XL (ตัว)", key: "2XL" },
  { label: "3XL (ตัว)", key: "3XL" },
]

export const orderInfer = {
  'ขาว': {
    'S': 0,
    'M': 0,
    'L': 0,
    'XL': 0,
    '2XL': 0,
    '3XL': 0
  },
  'น้ำเงิน': {
    'S': 0,
    'M': 0,
    'L': 0,
    'XL': 0,
    '2XL': 0,
    '3XL': 0
  },
  'ดำ': {
    'S': 0,
    'M': 0,
    'L': 0,
    'XL': 0,
    '2XL': 0,
    '3XL': 0
  }
};


