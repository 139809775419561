import React, { Component } from 'react'
import { DatePicker } from 'antd';
import { formatDateDB } from '../../helpers/time';

const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';
export default class OrderRangePicker extends Component {

  onRangePickerChange(date) {
    const startDate = formatDateDB(new Date(date[0]));
    const endDate = formatDateDB(new Date(date[1]));
    const { rangePickerChange } = this.props;
    rangePickerChange(startDate, endDate);
  }

  render() {
    return (
      <>
        <h3 className="">เลือกช่วงเวลาของข้อมูล(ตามเวลาเปลี่ยนสถานะล่าสุด)</h3>
        <RangePicker
          defaultValue={[]}
          onChange={(date) => this.onRangePickerChange(date)}
          format={dateFormat}
        />
      </>
    )
  }
}
