import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Form, Input, Button, Icon, Avatar } from 'antd'
import { AppPath } from '../../router/path';
import BaseLayout from '../../components/layout/Layout';
import { signIn } from '../../store/actions/admin-auth/adminAuth'

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errors: {},
      isLoading: false
    };
  }

  componentDidMount() {
    if (this.props.admins.isAuthenticated) {
      const param = new URLSearchParams(this.props.history.location.search);
      const path = param.get('path') || AppPath.admin.order;
      this.props.history.push(`${path}`);
    }
    this.props.form.validateFields();
  }


  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.signIn(values, this.props.history);
      }
    });
  };



  render() {
    const { getFieldDecorator, isFieldTouched, getFieldError, getFieldsError } = this.props.form;
    const { admins } = this.props
    const usernameError = isFieldTouched('username') && getFieldError('username');
    const passwordError = isFieldTouched('password') && getFieldError('password');
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    return (
      <BaseLayout>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}
          className=" verify-form mx-auto" >
          <div className="text-center">
            <Avatar src="./strong.PNG" shape="square" style={{ width: 150, height: 100 }} />
          </div>
          <h4 className="mx-auto text-center size-10">
            เข้าสู่ระบบ
      </h4>
          <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'ระบุ username' },
              ],
            })(
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="username"
              />,
            )}
          </Form.Item>
          <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'ระบุรหัสผ่าน' }],
            })(
              <Input.Password
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Password"
              />,
            )}
          </Form.Item>
          <Button block
            type="primary"
            htmlType="submit"
            shape="round"
            disabled={hasErrors(getFieldsError())}
            loading={admins.isFetching}
            className="ml-auto mb-3" icon="submit" >
            เข้าสู่ระบบ
      </Button>
        </Form>
      </BaseLayout>
    )
  }
}

const mapStateToProps = state => ({ admins: state.admins })
const LoginForm = Form.create({ name: 'login' })(AdminLogin);


export default connect(mapStateToProps, { signIn })(LoginForm)