import React from 'react'
import {
  Form,
  Select,
  InputNumber,
  Radio,
  Button,
  Row,
  Col,
  Input,
  Avatar,
  Modal,
  Spin,
  message,
  Icon,
  Divider,
  PageHeader
} from 'antd';
import SellService from '../../services/SellService';

const { Option } = Select;
const { TextArea } = Input;
let id = 0;
export const selectSize = [
  { value: 'S', text: 'S' },
  { value: 'M', text: 'M' },
  { value: 'L', text: 'L' },
  { value: 'XL', text: 'XL' },
  { value: '2XL', text: '2XL' },
  { value: '3XL', text: '3XL' }
  // ss, s, m, l, xl, xxl
];

class Demo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      visible: false,
      values: {
      },
    }
  }

  async componentDidMount() {
    const resp = await SellService.getUser(this.props.telNumber);
    if (resp.telNumber) {
      this.setState({ values: resp, isLoading: false })
    } else {
      this.setState({ isLoading: false })
    }
    this.add();

  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const newValue = { ...values };
        newValue.ordersList = [];
        newValue.count = 0;
        newValue.keys.forEach((k, i) => {
          newValue.ordersList[i] = {
            color: newValue.color[k],
            size: newValue.size[k],
            amount: newValue.amount[k]
          }
          newValue.count += newValue.amount[k]
        })
        if (newValue.typeResive === '1') {
          newValue.address = ''
        }
        this.setState({ values: newValue, visible: true })
      }
    });
  };

  sum = values => {
    let total = 200;
    if (values.typeResive === '2') {
      total = 260;
      if (values.count > 1) {
        total = (200 * values.count) + 60 + ((values.count - 1) * 10)
      }
    } else if (values.typeResive === '1' && values.count > 1) {
      total = (200 * values.count)
    }

    return total;
  }

  shirtCost = values => {
    let cost = 200;
    if (values.count > 1) {
      cost = (200 * values.count)
    }
    return cost;
  }

  shippingCost = values => {
    let cost = 60;
    if (values.count > 1) {
      cost = 60 + ((values.count - 1) * 10)
    }
    if (values.typeResive === '1') {
      cost = 0;
    }
    return cost;
  }

  async saveOrder() {
    const data = {
      ...this.state.values,
      total: this.sum(this.state.values),
      shirtCost: this.shirtCost(this.state.values),
      shippingCost: this.shippingCost(this.state.values),
      telNumber: this.props.telNumber
    }
    this.setState({ isLoading: true })
    const resp = await SellService.createOrder(data);
    this.setState({ isLoading: false })
    if (resp.id) {
      message.success('สั่งซื้อเสื้อสำเร็จ กรุณาชำระเงิน');
      this.setState({ visible: false })
      this.props.nextTab();
    } else {
      return message.warn('ทำรายการไม่สำเร็จ');
    }
  }

  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  renderListOrder(values) {
    if (values.ordersList) {
      return values.ordersList.map((value, i) => {
        return (
          <div key={i}>
            <Row type="flex" justify="space-between">
              <Col>
                <h4>สีเสื้อ: </h4>
              </Col>
              <Col>
                <h4>{value.color} </h4>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col>
                <h4>ขนาดเสื้อ: </h4>
              </Col>
              <Col>
                <h4>{value.size}</h4>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col>
                <h4>จำนวน:</h4>
              </Col>
              <Col>
                <h4> {value.amount} ตัว</h4>
              </Col>
            </Row>
            <Divider type="horizontal" />
          </div>
        )
      })
    }

  }


  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { isLoading, visible, values } = this.state;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    const typeResive = getFieldValue('typeResive');


    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => (
      <React.Fragment key={k}>
        {keys.length > 1 ? (
          <div className="text-right">
            <Icon
              className="dynamic-delete-button"
              type="minus-circle-o"
              onClick={() => this.remove(k)}
            />
          </div>
        ) : null}
        <Form.Item label="สีเสื้อ"
          key={k}>
          {getFieldDecorator(`color[${k}]`, {
            rules: [{ required: true, message: 'กรุณาระบุสีเสื้อ' }]
          })(
            <Radio.Group>
              <Radio value="ขาว">ขาว</Radio>
              <Radio value="ดำ">ดำ</Radio>
              <Radio value="น้ำเงิน">น้ำเงิน</Radio>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item label="ขนาดเสื้อ" hasFeedback>
          {getFieldDecorator(`size[${k}]`, {
            rules: [{ required: true, message: 'กรุณาเลือกขนาดเสื้อ' }],
          })(
            <Select placeholder="เลือกขนาดเสื้อ">
              {selectSize.map((size, i) => {
                return <Option key={i} value={size.value}>{size.text}</Option>
              })}
            </Select>,
          )}
        </Form.Item>

        <Form.Item label="จำนวน" className="mb-0">
          {getFieldDecorator(`amount[${k}]`, {
            initialValue: 1,
            rules: [{ required: true, message: 'กรุณาระบุจำนวน' }],
          })(<InputNumber min={1} max={100} />)}
          <span className="ant-form-text"> ตัว  (ราคาตัวละ 200 บาท)</span>
        </Form.Item>
        <Divider type="horizontal" />
      </React.Fragment>
    ));

    return (
      <React.Fragment>
        <Modal
          width={320}
          visible={visible}
          closable={false}
          footer={null}
        >
          <div >
            <h3 className="text-center">ยืนยันการเลือก</h3>
            <div>
              {this.renderListOrder(values)}
              {values.typeResive === '2' ?
                <>
                  <h4>จัดส่งไปยัง:</h4>
                  <h4 className="ml-2">{values.address}</h4>
                </> :
                <h4>รับเอง</h4>
              }

              <Row type="flex" justify="space-between">
                <Col>
                  <h4>ค่าเสื้อ </h4>
                </Col>
                <Col>
                  <h4>{this.shirtCost(values)} บาท</h4>
                </Col>
              </Row>
              {values.typeResive === '2' &&
                <Row type="flex" justify="space-between">
                  <Col>
                    <h4>ค่าจัดส่ง </h4>
                  </Col>
                  <Col>
                    <h4>{this.shippingCost(values)} บาท</h4>
                  </Col>
                </Row>
              }
              <Row type="flex" justify="space-between">
                <Col>
                  <h4>รวมเป็นเงิน </h4>
                </Col>
                <Col>
                  <h4>{this.sum(values)} บาท</h4>
                </Col>
              </Row>
            </div>
          </div>
          <Row type="flex" className="mt-3">
            <Col span={24} >
              <Button block key="ok"
                loading={isLoading}
                type="primary"
                className="f-main size-10"
                onClick={() => this.saveOrder()} >
                ยืนยัน
            </Button>
            </Col>
            <Col span={24} className="mt-2">
              <Button block key="back"
                className=" size-10"
                onClick={() => this.setState({ ...this.state, visible: false })} >
                ยกเลิก
            </Button>
            </Col>
          </Row>
        </Modal>
        <Spin spinning={this.state.isLoading} tip="Loading...">

          <Form
            {...formItemLayout}
            onSubmit={this.handleSubmit}
            className="form-select">
            <PageHeader
              onBack={() => this.props.goHome()}
              subTitle="ไปยังหน้ากรอกเบอร์โทร"
            />
            <div className="mt-3 text-center mb-3">
              <span className="size-10 bold">กรุณากรอกรายละเอียดสำหรับการสั่งซื้อ</span>
            </div>
            <Row type="flex" justify="center">
              <Col className="mr-2 text-center">
                <Avatar src="./price.jpg" shape="square" style={{ width: 220, height: 170 }} />
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col className="mr-2 text-center">
                <Avatar src="./white.jpg" shape="square" size={100} />
              </Col>
              <Col className="mr-2 text-center">
                <Avatar src="./black.jpg" shape="square" size={100} />
              </Col>
              <Col className="mr-2 text-center">
                <Avatar src="./blue.jpg" shape="square" size={100} />
              </Col>
            </Row>
            <div className="mt-2  mb-2">
              <span className="size-09 bold">ข้อมูลผู้สั่งซื้อ :</span>
            </div>
            <Form.Item label="ชื่อ" className="mt-3">
              {getFieldDecorator('name', {
                initialValue: values.name,
                rules: [{ required: true, message: 'กรุณาระบุชื่อ' }],
              })(
                <Input />)}
            </Form.Item>
            <Form.Item label="นามสกุล">
              {getFieldDecorator('lastname', {
                initialValue: values.lastname,
                rules: [{ required: true, message: 'กรุณาระบุนามสกุล' }],
              })(
                <Input />)}
            </Form.Item>
            <Form.Item label="การรับเสื้อ" style={{ display: 'none' }}>
              {getFieldDecorator('typeResive', {
                initialValue: '2',
                rules: [{ required: true, message: 'กรุณาระบุประเภทการรับเสื้อ' }],
              })(
                <Radio.Group>
                  <Radio value="1">รับเอง</Radio>
                  <Radio value="2">จัดส่ง</Radio>
                </Radio.Group>,
              )}
            </Form.Item>
            {typeResive === '2' ? <Form.Item label="ที่อยู่">
              {getFieldDecorator('address', {
                initialValue: values.address,
                rules: [{ required: true, message: 'กรุณาระบุที่อยู่' }],
              })(
                <TextArea placeholder="ตัวอย่าง 271/49 ตรอกวัดท่าตะโก ต.ในเมือง อ.เมือง จ.นครราชสีมา 30000" autoSize />)}
              <span style={{ lineHeight: '1rem' }} className="ant-form-text">*ค่าจัดส่งตัวแรก 60 บาท สั่งมากกว่า 1 ตัวเพิ่มตัวละ 10 บาท</span>
            </Form.Item> : null}

            <div className="mt-2  mb-2">
              <span className="size-09 bold">ข้อมูลเสื้อที่สั่งซื้อเสื้อ :</span>
            </div>
            {formItems}
            <Form.Item>
              <Col className="text-center" span={24} style={{ marginBottom: '1rem' }}>
                <Button type="dashed" disabled={false} onClick={this.add} >
                  <Icon type="plus" /> เลือกเพิ่มเติม
               </Button>
              </Col>
            </Form.Item>


            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
              <Button type="primary" disabled={false} loading={isLoading} htmlType="submit">
                ยืนยันการสั่งซื้อ
          </Button>
            </Form.Item>
          </Form >
        </Spin>
      </React.Fragment>
    );
  }
}

const SelectProduct = Form.create({ name: 'validate_other' })(Demo);
export default SelectProduct;