import React, { Component } from 'react'
import { Card, Row, Descriptions, Col, Avatar, Modal, Form, Radio, Button, Tag, message } from 'antd'
import Status from './Status'
import AdminService from '../../services/AdminService'
import TextArea from 'antd/lib/input/TextArea'

class Order extends Component {
  state = {
    previewVisible: false,
    previewImage: '',
    isLoading: false,
    order: {}
  }



  renderOrderItem(ordersList) {
    return ordersList.map((order, i) => {
      return <React.Fragment key={i}>
        <Descriptions.Item label="สีเสื้อ">{order.color} </Descriptions.Item>
        <Descriptions.Item label="ขนาดเสื้อ">{order.size}</Descriptions.Item>
        <Descriptions.Item label="จำนวน"> {order.amount} ตัว</Descriptions.Item>
      </React.Fragment>
    })
  }

  handlePreview = url => {
    this.setState({
      previewImage: url,
      previewVisible: true,
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {

        try {
          const { order, setOrder } = this.props;
          const data = { id: order.id, status: values.slipStatus, refId: values.refId }
          if (values.tagId) {
            data.tagId = values.tagId
          }



          if (values.tagId === order.tagId && values.slipStatus === order.slipStatus) return;
          this.setState({ isLoading: true })
          const resp = await AdminService.updateOrderStatus(data);
          message.success('เปลี่ยนแปลงสถานะสำเร็จ');
          setOrder(resp)
          this.setState({ isLoading: false })
        } catch (error) {
          this.setState({ isLoading: false })
          message.error('เกิดข้อผิดพลาด')
          console.log(error);
        }
      }
    })
  }



  renderImage() {
    const { order } = this.props;
    return (
      <Row type="flex" justify="center" className="mb-3" gutter={[8, 8]}>
        {order.images.map((image, i) => {
          return (
            <Col key={i} span={6} className="pointer" onClick={() => this.handlePreview(image.imagePath)}>
              <Avatar shape="square" style={{ width: '100%', height: '6rem' }} src={image.imagePath} />
            </Col>)
        })}
      </Row>
    )
  }

  render() {
    const { order } = this.props;
    const { previewVisible, previewImage, isLoading } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const getSlipStatus = getFieldValue('slipStatus');
    return (
      <Card>
        <div className="clearfix">

          <Modal visible={previewVisible} footer={null} onCancel={() => { this.setState({ previewVisible: false }) }}>
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </div>
        <Status status={order.slipStatus} />
        <Card>
          <Descriptions title="ข้อมูลลูกค้า">
            <Descriptions.Item label="ชื่อ - นามสกุล">{order.user.name} {order.user.lastname}</Descriptions.Item>
            <Descriptions.Item label="หมายเลขโทรศัพท์">{order.user.telNumber}</Descriptions.Item>
            <Descriptions.Item label="ที่อยู่">
              {order.address}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title={`ข้อมูลสินค้า (เลขออเดอร์  ${order.id})`}>
            {this.renderOrderItem(order.ordersList)}
            <Descriptions.Item label="ราคาเสื้อ">{order.shirtCost} บาท</Descriptions.Item>
            <Descriptions.Item label="ค่าจัดส่ง">{order.shippingCost} บาท</Descriptions.Item>
            <Descriptions.Item label="รวมเป็นเงิน">{order.total} บาท</Descriptions.Item>
            {this.renderImage()}
          </Descriptions>

          <Form
            onSubmit={this.handleSubmit}
            className="form-select">
            <Descriptions title="เปลี่ยนสถานะ">
            </Descriptions>
            <Form.Item label="เลขที่อ้างอิงรายการ" style={{ marginBottom: 0 }}  >
              {getFieldDecorator('refId', {
                initialValue: order.refId,
              })(
                <TextArea placeholder="ตัวอย่าง 20990299" autoSize />)}
            </Form.Item>
            <Form.Item label="สถานะ" style={{ marginBottom: 0 }} >
              {getFieldDecorator('slipStatus', {
                initialValue: order.slipStatus,
                rules: [{ required: true, message: 'ระบบสถานะ' }],
              })(
                <Radio.Group>
                  <Radio value={1} className="mb-2"><Tag color="orange" >ตรวจสอบการชำระเงิน</Tag></Radio>
                  <Radio value={2} className="mb-2"><Tag color="lime" >ชำระเงินถูกต้อง</Tag></Radio>
                  <Radio value={3} className="mb-2"><Tag color="gold" >อยู่ระหว่างจัดส่ง</Tag></Radio>
                  <Radio value={4} className="mb-2"><Tag color="green" >ทำรายการสำเร็จ</Tag></Radio>
                  <Radio value={100} className="mb-2"><Tag color="red" >ยกเลิกออเดอร์</Tag></Radio>

                </Radio.Group>,
              )}
            </Form.Item>

            {getSlipStatus === 3 || order.slipStatus === 3 ? <Form.Item label="เลขที่พัสดุ">
              {getFieldDecorator('tagId', {
                initialValue: order.tagId,
                rules: [{ required: true, message: 'กรุณาระบุเลขที่พัสดุ' }],
              })(
                <TextArea placeholder="ตัวอย่าง AB12345678" autoSize />)}
            </Form.Item> : null}

            <Button type="primary" className="mt-3" loading={isLoading} htmlType="submit">
              ยืนยันการเปลี่ยนสถานะ
          </Button>
          </Form>
        </Card>
      </Card>
    )
  }
}
const OrderForm = Form.create({ name: 'order' })(Order);

export default OrderForm