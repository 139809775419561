import React, { Component } from 'react'
import { Layout } from 'antd';

const { Content } = Layout;

export default class BaseLayout extends Component {
  render() {
    return (
      <Layout className="layout" style={{ minHeight: '100vh' }}>
        <Content style={{ padding: '0 5%', display: 'flex', alignItems: 'center' }}>
          <div style={{ background: '#fff', padding: 24, minHeight: 280, width: '100%', display: 'flex' }}>
            {this.props.children}
          </div>
        </Content>
      </Layout>
    )
  }
}
