import React, { Component } from 'react'
import { Menu, Icon, Affix, Tag, Divider } from 'antd';
import { connect } from 'react-redux';

import Container from '../Container';
import { AppPath } from '../../../router/path';
import AdminService from '../../../services/AdminService'
import { withRouter } from 'react-router-dom';

const { SubMenu } = Menu
class AdminLayoutHeader extends Component {

  state = {
    sms: 0
  }

  async componentDidMount() {
    const resp = await AdminService.getSms();
    if (resp.balance) {
      this.setState({ sms: resp.balance })
    }
  }

  handleClick = e => {
    if (e.key === '/logout') {
      return AdminService.logout();
    }
    const { history } = this.props;
    history.push({ pathname: `/admin${e.key !== ' ' ? e.key : ''}` })
  };

  render() {
    const { location } = this.props.history
    let pathname = location.pathname.split('/admin')[1] || ' ';


    return (
      <Affix offsetTop={0} style={{ zIndex: 1000 }} >
        <React.Fragment>
          <div className="bg-w">
            <Container>
              <div className="text-right bg-w">
                <Tag className="ml-auto" style={{ marginRight: 0 }} color="green">
                  sms คงเหลือ : {this.state.sms}</Tag>
                <Divider type="horizontal" style={{ margin: 0 }} />
              </div>
              <Menu
                className="d-flex bb-un"
                onClick={this.handleClick}
                selectedKeys={pathname}
                mode="horizontal"
              >
                {/* <Menu.Item key=" ">
                  <Icon type="home" />
                  หน้าแรก
                </Menu.Item> */}
                <Menu.Item key="/order">
                  <Icon type="shopping" />
                  ออเดอร์
                </Menu.Item>
                <SubMenu
                  title={
                    <span className="submenu-title-wrapper">
                      <Icon type="shopping" />
                      ออเดอร์ตามสถานะ
            </span>
                  }
                >
                  <Menu.Item key="/create">
                    <Icon type="shopping" />
                    ส่งซื้อ
                </Menu.Item>
                  <Menu.Item key="/verify">
                    <Icon type="shopping" />
                    ตรวจสอบการชำระเงิน
                </Menu.Item>
                  <Menu.Item key="/isverifysuccess">
                    <Icon type="shopping" />
                    ชำระเงินถูกต้อง
                </Menu.Item>
                  <Menu.Item key="/sending">
                    <Icon type="shopping" />
                    อยู่ระหว่างจัดส่ง
                </Menu.Item>
                  <Menu.Item key="/success">
                    <Icon type="shopping" />
                    ทำรายการสำเร็จ
                </Menu.Item>
                  <Menu.Item key="/cancel">
                    <Icon type="shopping" />
                    ยกเลิกออเดอร์
                </Menu.Item>
                </SubMenu>
                <Menu.Item key="/user">
                  <Icon type="team" />
                  สมาชิก
                </Menu.Item>
                <Menu.Item key={AppPath.logout} className="ml-auto" >
                  <Icon type="logout" />
                  ออกจากระบบ
                    </Menu.Item>

              </Menu>
            </Container>
          </div>
        </React.Fragment>
      </Affix>
    )
  }
}

const mapStateToProps = state => ({ admins: state.admins })

export default connect(mapStateToProps)(withRouter(AdminLayoutHeader))