const prefix = '/admin'
export const AppPath = {
  home: '/home',
  list: '/check-register-run2020',
  product: '/product',
  logout: '/logout',
  admin: {
    home: prefix,
    login: `${prefix}/login`,
    user: `${prefix}/user`,
    order: `${prefix}/order`,
    orderCreate: `${prefix}/create`,
    orderVerify: `${prefix}/verify`,
    orderVerifySuccess: `${prefix}/isverifysuccess`,
    orderSending: `${prefix}/sending`,
    orderSuccess: `${prefix}/success`,
    orderCancel: `${prefix}/cancel`
  }
}