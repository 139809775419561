import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { message } from 'antd';
import { AppPath } from './path';

const PrivateRoute = ({ component: Component, admins, ...rest }) => {
  useEffect(() => {
    if (!admins.isAuthenticated)
      message.warn('กรุณาเข้าสู่ระบบ');
  })

  return (<Route {...rest} render={(props) => (
    admins.isAuthenticated === true
      ? <Component {...props} />
      :
      <Redirect to={`${AppPath.admin.login}?path=${props.location.pathname}${props.location.search}`} />

  )} />)
}


const mapStateToProps = state => ({ admins: state.admins })


export default connect(mapStateToProps)(PrivateRoute)
