import { ORDER_URL } from '../config';
import http from "./HttpService";
import FormData from 'form-data'

const AppService = {
  async getAllShop() {
    const url = `${ORDER_URL}get_all_members_run2020`;
    return await http.requestPost(url);
  },
  objectToFormData: (data = {}) => {
    const form = new FormData();
    Object.entries(data).forEach(item => {
      form.append(item[0], item[1] || '');
    })
    return form;
  },
}
export default AppService;