import React, { Component } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import { getProfile } from './store/actions/admin-auth/adminAuth'
import { AppPath } from './router/path';
import Home from './pages/Home';
import DataList from './pages/DataList';
import Product from './pages/Product';
import { connect } from 'react-redux';
import PrivateRoute from './router/PrivateRoute';
import AdminHome from './pages/admin/AdminHome';
import AdminUsers from './pages/admin/AdminUsers';
import AdminOrders from './pages/admin/AdminOrders';
import AdminOrder from './pages/admin/AdminOrder';
import AdminLogin from './pages/admin/AdminLogin';
import AdminOrderCancel from './pages/admin/AdminOrderCancel';
import AdminOrderCreate from './pages/admin/AdminOrderCreate';
import AdminOrderVerify from './pages/admin/AdminOrderVerify';
import AdminOrderVerifySuccess from './pages/admin/AdminOrderVerifySuccess';
import AdminOrderSending from './pages/admin/AdminOrderSending';
import AdminOrderSuccess from './pages/admin/AdminOrderSuccess';
import AdminUser from './pages/admin/AdminUser';
import { Font } from '@react-pdf/renderer';

Font.register({ family: 'thai', src: "./THSarabunNew.ttf" });
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isFirstGet: true
    }
  }


  componentDidMount() {
    this.props.getProfile(this.props.history).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  render() {
    if (this.state && this.state.isLoading === false) {
      return (
        <Switch>
          <Route exact path="/" >
            <Redirect to={AppPath.home}></Redirect>
          </Route>
          <Route path={AppPath.home} component={Home} />
          <Route path={AppPath.list} component={DataList} />
          <Route path={AppPath.product} component={Product} />
          <Route path={AppPath.admin.login} component={AdminLogin} />
          <PrivateRoute path={`${AppPath.admin.order}/:id`} component={AdminOrder} />
          <PrivateRoute path={AppPath.admin.orderCreate} component={AdminOrderCreate} />
          <PrivateRoute path={AppPath.admin.orderVerify} component={AdminOrderVerify} />
          <PrivateRoute path={AppPath.admin.orderVerifySuccess} component={AdminOrderVerifySuccess} />
          <PrivateRoute path={AppPath.admin.orderSending} component={AdminOrderSending} />
          <PrivateRoute path={AppPath.admin.orderSuccess} component={AdminOrderSuccess} />
          <PrivateRoute path={AppPath.admin.orderCancel} component={AdminOrderCancel} />
          <PrivateRoute path={AppPath.admin.order} component={AdminOrders} />
          <PrivateRoute path={`${AppPath.admin.user}/:id`} component={AdminUser} />
          <PrivateRoute path={AppPath.admin.user} component={AdminUsers} />
          <PrivateRoute path={AppPath.admin.home} component={AdminHome} />
        </Switch>
      );
    } else {
      return (
        <Spin tip="Loading..." >
          <div style={{ minHeight: '100vh' }}></div>
        </Spin>
      )
    }
  }

}


const mapStateToProps = state => ({ admins: state.admins })

export default connect(mapStateToProps, { getProfile })(withRouter(App));

