import React, { Component } from 'react'
import { Spin } from 'antd';
import AdminBaseLayout from '../../components/layout/admin/AdminBaseLayout'
import AdminService from '../../services/AdminService';
import Order from '../../components/admin/Order';

export default class AdminUser extends Component {
  state = {
    userOrderData: [{
      user: {
        name: 'ระบบ',
        lastname: 'ระบบ'
      }
    }],
    isLoading: true
  }

  async componentDidMount() {
    const { match } = this.props;
    const id = match.params.id;
    try {
      const resp = await AdminService.getUserOrders(id);
      this.setState({ userOrderData: resp, isLoading: false })
    } catch (error) {
      this.setState({ isLoading: false })
      console.log(error)
    }
  }

  setOrder(order) {
    const { userOrderData } = this.state;
    const index = userOrderData.findIndex(item => item.id === order.id);
    if (index !== -1) {
      userOrderData[index] = order;
      this.setState({ userOrderData })
    }
  }

  renderUserOrders() {
    const { userOrderData } = this.state;
    return userOrderData.map(order => (<div key={order.id} className="mt-3">
      <Order
        order={order}
        setOrder={this.setOrder.bind(this)} /></div>))
  }





  render() {
    const { isLoading, userOrderData } = this.state;
    return (
      <AdminBaseLayout>
        <div className="mt-3">
          <h3>ออเดอร์ทั้งหมดของ {userOrderData[0].user.name} {userOrderData[0].user.lastname}</h3>
          {isLoading ? <Spin tip="Loading..." >
            <div style={{ minHeight: '80vh' }}></div>
          </Spin> : this.renderUserOrders()}
        </div>
      </AdminBaseLayout>
    )
  }
}
