import React, { Component } from 'react'
import AdminBaseLayout from '../../components/layout/admin/AdminBaseLayout'
import AdminService from '../../services/AdminService';
import UserList from '../../components/admin/UserList';
import { Spin } from 'antd';

export default class AdminUsers extends Component {

  state = {
    userData: [],
    isLoading: true
  }

  async componentDidMount() {
    try {
      const resp = await AdminService.getUsers();
      this.setState({ userData: resp, isLoading: false })
    } catch (error) {
      this.setState({ isLoading: false })
      console.log(error)
    }
  }

  render() {
    const { userData, isLoading } = this.state;
    return (
      <AdminBaseLayout>
        <div className="mt-3">
          {isLoading ? <Spin tip="Loading..." >
            <div style={{ minHeight: '80vh' }}></div>
          </Spin> : <UserList title="สมาชิกทั้งหมด" userData={userData} />}
        </div>

      </AdminBaseLayout>
    )
  }
}
