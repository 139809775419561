import React, { Component } from 'react';
import { Table, Input, Button, Icon } from 'antd';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { setThaiDate } from '../../helpers/time';
import { AppPath } from '../../router/path';

const { Column } = Table;
export default class UserList extends Component {
  state = {
    searchText: '',
    searchedColumn: '',
    isLoading: false,
  };


  getColumnSearchProps = (dataIndex, text) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`ค้นหา ${text}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          ค้นหา
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          รีเซ็ต
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  render() {
    const { title, userData } = this.props;
    return (
      <>
        <h3 >{title}</h3>
        <Table
          dataSource={userData}
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, total: userData.length, showTotal: (total, range) => `${range[0]}-${range[1]} จาก ${total} คน` }}
          scroll={{ x: 1100 }}
        >
          <Column title="รหัสสมาชิก"
            align="center"
            dataIndex="id"
            width={100}
            key="id"
            {...this.getColumnSearchProps('id', 'รหัสออเดอร์')}
          />
          <Column title="ชื่อ"
            dataIndex="name"
            ellipsis
            width={100}
            key="name"
            {...this.getColumnSearchProps('name', 'ชื่อ')}
          />
          <Column title="นามสกุล"
            dataIndex="lastname"
            ellipsis
            width={100}
            key="lastname"
            {...this.getColumnSearchProps('lastname', 'นามสกุล')}
          />
          <Column title="เบอร์โทร"
            dataIndex="telNumber"
            ellipsis
            width={100}
            key="telNumber"
            {...this.getColumnSearchProps('telNumber', 'เบอร์โทร')}
          />

          <Column title="จำนวนออเดอร์ทั้งหมด"
            dataIndex="orderCount"
            align="center"
            ellipsis
            sorter={(a, b) => a.orderCount - b.orderCount}
            width={100}
            key="orderCount"
          />

          <Column title="วันที่ลงทะเบียน"
            ellipsis
            width={100}
            key="created"
            defaultSortOrder
            sorter={(a, b) => new Date(a.created) - new Date(b.created)}
            render={(text, record) => (
              setThaiDate(record.created)
            )}
          />
          <Column title="Action"
            fixed="right"
            width={200}
            key="action"
            render={(text, record) => (
              <Link to={`${AppPath.admin.user}/${record.id}`}> รายละเอียดสมาชิก </Link>
            )}
          />
        </Table>
      </>
    )
  }
}
