import React from 'react';
import { Row, Col } from 'antd';

const Container = (props) => {
  return (
    <Row type="flex" justify="center">
      <Col span={23} xs={23} sm={23} md={20} lg={18}>
        {props.children}
      </Col>
    </Row>
  );
}

export default Container;
