import React from 'react';
import TableList from '../components/list/TableList';
import Container from '../components/desktop/Container';
import { PageHeader } from 'antd';

const DataList = () => {
  return (
    <Container>
      <PageHeader
        style={{
          border: '1px solid rgb(235, 237, 240)',
          marginTop: '2rem',
          marginBottom: '1rem'
        }}
        title="ตรวจสอบรายชื่อและข้อมูลผู้สมัคร"
      />
      <TableList />
      <div style={{ marginBottom: '2rem' }}></div>
    </Container>
  );
}

export default DataList;
