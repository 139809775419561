import { runRequestGet, runRequestPost, runRequestPatch } from "../helpers/fetch";
import { message } from "antd";

class Admin {

  setToken(resp) {
    localStorage.setItem('token', resp.accessToken);
    localStorage.setItem('rftoken', resp.refreshToken);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getRefreshToken() {
    return localStorage.getItem('rftoken');
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('rftoken');
    window.location.reload();
  }

  async response(resp) {
    if (resp.ok) {
      return resp.json();
    } else if (resp.status === 401) { // ไม่ได้เข้าสู่ระบบ
      const errResp = await resp.json()
      if (errResp.message === 'Invalid credentials') {
        throw errResp;
      } else {
        const rfResp = await this.getNewToken();
        if (rfResp) {
          return true
        } else {
          message.warning('token หมดอายุ');
          this.logout();
          throw errResp;
        }
      }
    } else if (resp.status === 400) { // ไม่ได้รับสิทธิ์
      throw await resp.json()
    } else if (resp.status === 403) { // bad request
      throw await resp.json()
    } else if (resp.status === 406) { //ข้อมูลบางอย่างไม่ถูกต้อง
      throw await resp.json()
    } else {
      message.error('เกิดข้อผิดพลาด')
      throw await resp.json()
    }
  }

  async getNewToken() {
    const resp = await runRequestPost(`auth/admin/gettoken`, { refreshToken: this.getRefreshToken() })
    if (resp.ok) {
      const newResp = await this.response(resp);
      this.setToken(newResp);
      return true;
    } else {
      return false;
    }
  }

  async  getProfile() {
    const resp = await runRequestGet(`admin`, this.getToken())
    const newResp = await this.response(resp)
    if (newResp === true) {
      return await this.getProfile();
    } else {
      return newResp
    }
  }

  async signIn(data) {
    const resp = await runRequestPost(`auth/admin/signin`, data);
    return await this.response(resp)
  }

  async getOrders(status, startDate, endDate) {
    let url = `admin/orders`
    if (status && (!startDate || !endDate)) {
      url = `admin/orders?status=${status}`;
    } else if (!status && startDate && endDate) {
      url = `admin/orders?startDate=${startDate}&endDate=${endDate}`;
    } else if (status && startDate && endDate) {
      url = `admin/orders?status=${status}&startDate=${startDate}&endDate=${endDate}`;
    }
    const resp = await runRequestGet(url, this.getToken());
    const newResp = await this.response(resp)
    if (newResp === true) {
      return await this.getOrders(status, startDate, endDate)
    } else {
      return newResp
    }
  }

  async getOrderById(id) {
    const resp = await runRequestGet(`admin/order/${id}`, this.getToken());
    const newResp = await this.response(resp)
    if (newResp === true) {
      return await this.getOrderById(id)
    } else {
      return newResp
    }
  }

  async updateOrderStatus(data) {
    const resp = await runRequestPatch(`admin/order`, data, this.getToken());
    const newResp = await this.response(resp)
    if (newResp === true) {
      return await this.updateOrderStatus(data)
    } else {
      return newResp
    }
  }

  async getUsers() {
    const resp = await runRequestGet(`admin/users`, this.getToken());
    const newResp = await this.response(resp)
    if (newResp === true) {
      return await this.getUsers();
    } else {
      return newResp
    }
  }

  async getUserOrders(id) {
    const resp = await runRequestGet(`admin/userorders/${id}`, this.getToken());
    const newResp = await this.response(resp)
    if (newResp === true) {
      return await this.getUserOrders(id);
    } else {
      return newResp
    }
  }

  async getSms() {
    try {
      const response = await fetch(`https://api2.ants.co.th/account/1/balance`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + window.btoa("Onnetthai:OnnetSMS8811")
        },
      });
      return response.json();
    } catch (error) {
      throw error;
    }
  }

}

const AdminService = new Admin();
export default AdminService;