import React, { Component } from 'react'
import { Modal, Divider, Button } from 'antd'
import Order from './Order'

export default class ModalOrder extends Component {


  render() {
    const { visible, order, setVisible, setOrder } = this.props
    return (
      <Modal
        title="เปลี่ยนสถานะออเดอร์"
        onCancel={() => setVisible(false)}
        style={{ top: 20 }}
        visible={visible}
        footer={false}
      >
        <Order order={order} setOrder={setOrder} />
        <Divider type="horizontal" />
        <div className="text-center">
          <Button type="ghost" onClick={() => setVisible(false)}>
            ปิด
        </Button>
        </div>
      </Modal>
    )
  }
}
