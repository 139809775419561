import moment from 'moment'

export const setThaiDate = (date = new Date(), type = "/") => {
  return moment(date).format(`DD${type}MM${type}YYYY HH:mm`)
}

export const setThaiDateOnly = (date = new Date(), type = "/") => {
  return moment(date).format(`DD${type}MM${type}YYYY`)
}

export const formatDateDB = (date = new Date()) => {
  return moment(date).format(`YYYY-MM-DD`);
}