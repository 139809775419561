import React, { Component } from 'react'
import AdminBaseLayout from '../../components/layout/admin/AdminBaseLayout'
import AdminService from '../../services/AdminService'
import OrderList from '../../components/admin/OrderList';
import { Spin } from 'antd';
export default class AdminOrderCancel extends Component {

  state = {
    orderData: [],
    isLoading: true,
    startDate: null,
    endDate: null
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData(startDate, endDate) {
    try {
      const resp = await AdminService.getOrders('100', startDate, endDate);
      const orderData = resp.map(order => {
        order.name = order.user.name;
        order.lastname = order.user.lastname;
        order.telNumber = order.user.telNumber;
        return order
      })
      this.setState({ orderData, isLoading: false })
    } catch (error) {
      this.setState({ isLoading: false })
      console.log(error)
    }
  }

  rangePickerChange(startDate, endDate) {
    if (startDate === "Invalid date" || endDate === "Invalid date") {
      startDate = '';
      endDate = ''
    }
    this.setState({ startDate, endDate });
    this.fetchData(startDate, endDate);
  }

  setOrder(order) {
    const { orderData } = this.state;
    const index = orderData.findIndex(item => item.id === order.id);
    if (index !== -1) {
      orderData[index] = order;
      this.setState({ orderData })
    }
  }

  render() {
    const { orderData, isLoading } = this.state
    return (
      <AdminBaseLayout >
        <div className="mt-3">
          {isLoading ? <Spin tip="Loading..." >
            <div style={{ minHeight: '80vh' }}></div>
          </Spin> : <OrderList title="ออเดอร์ที่ถูกยกเลิก"
            setOrder={this.setOrder.bind(this)}
            orderData={orderData} />}
        </div>
      </AdminBaseLayout>
    )
  }
}

