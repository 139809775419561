import React from 'react';
import { Avatar, Row } from 'antd';

const Home = () => {
  return (
    <Row type="flex" justify="center" style={{ minHeight: '100vh' }}>
      <div
        style={{ width: '40%', height: 'auto', margin: 'auto' }}>
        <Avatar shape="square"
          src="./logo.jpg"
          style={{ width: '100%', height: 'auto', margin: 'auto' }}
        />
      </div>
    </Row>

  );
}

export default Home;
