import React, { Component } from 'react'
import { Form, Input, Button, Col, Icon, Row, message, Avatar } from 'antd'
import SellService from '../../services/SellService'

class VerifyPhone extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isOtpLoading: false,
      isVerify: false,
      telNumber: '',
      countDown: 30,
      showCount: false
    }

  }

  handleSendOtp = async () => {
    const telNumber = this.props.form.getFieldValue('telNumber');
    const telError = this.props.form.getFieldError('telNumber')
    if ((!telError && !telNumber)) {
      return message.error('กรุณาระบุหมายเลขโทรศัพท์');
    } else if (telError) {
      return message.error('กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง')
    }
    const postData = { 'to': telNumber, user: 'test', 'm_tel_number': telNumber, telNumber };
    this.setState({ isOtpLoading: true, telNumber })
    await SellService.sendOtp(postData)
    this.setState({ isOtpLoading: false, showCount: true, countDown: 30 })

    const time = setInterval(() => {
      if (this.state.countDown === 0) {
        clearInterval(time);
        this.setState({ showCount: false })
      }
      else
        this.setState({ countDown: this.state.countDown - 1 })
    }, 1000)


  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const postData = { telNumber: values.telNumber, otp: values.code }
        this.setState({ isLoading: true })
        const checkOtp = await SellService.checkOtp(postData)
        if (checkOtp) {
          this.props.setVerify(values.telNumber);
        } else {
          this.setState({ isLoading: false })
          message.warn('รหัส opt ไม่ถูกต้อง หรือ หมดอายุ');
        }

      }
    });
  };


  render() {
    const { getFieldDecorator } = this.props.form;
    const { showCount, countDown } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <>
        <div className="mx-auto mt-auto mb-auto">
          {/* <div className="text-center">
            <Avatar src="./strong.PNG" shape="square" style={{ width: 150, height: 100 }} />
          </div> */}
          {/* <h2>ขออภัยขณะนี้ระบบสั่งซื้อออนไลน์ได้ปิดแล้ว</h2> */}
          <div className="text-center" style={{ marginTop: '-2rem' }}>
            <h2>ยินดีต้อนรับสู่หน้าการสั่งซื้อเสื้อ KoratStrong</h2>
          </div>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}
            className=" verify-form " style={{ marginTop: '2rem' }}>
            <div className="text-center">
              <Avatar src="./strong.PNG" shape="square" style={{ width: 150, height: 100 }} />
            </div>
            <h4 className="mx-auto text-center size-10">
              ยืนยันหมายเลขโทรศัพท์สำหรับสั่งซื้อ
        </h4>
            <Form.Item label="โทรศัพท์" className="mt-3">
              <Row gutter={8}>
                <Col span={16}>
                  {getFieldDecorator('telNumber', {
                    rules: [{ required: true, message: 'ระบุหมายเลขโทรศัพท์' },
                    { len: 10, message: 'หมายเลขโทรศัพท์ไม่ถูกต้อง' }],
                  })(
                    <Input
                      prefix={<Icon type="phone"
                        style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="กรอกหมายเลขโทรศัพท์"
                    />,
                  )}
                </Col>
                <Col span={8}>
                  <Button type="primary" shape="round"
                    loading={this.state.isOtpLoading}
                    disabled={showCount}
                    onClick={this.handleSendOtp}>
                    {showCount ? <span>ส่งได้อีกครั้งใน {countDown}</span> : 'ส่ง otp'}   </Button>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label="OTP">
              {getFieldDecorator('code', {
                rules: [
                  {
                    required: true,
                    message: 'กรอก OTP',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Button block type="primary" htmlType="submit" shape="round"
              loading={this.state.isLoading}
              className="ml-auto mb-3" icon="submit" >
              ยืนยัน
        </Button>
          </Form>
        </div>
      </>
    )
  }
}

const VerifyForm = Form.create({ name: 'verify' })(VerifyPhone);

export default VerifyForm