import AdminService from "../../../services/AdminService";
import { adminProfileSuccess, adminProfileError, adminAuthRequest, adminAuthSuccess, adminAuthError } from "./adminAuthAction";
import { message } from "antd";
import { AppPath } from "../../../router/path";


export const getProfile = (history) => dispatch => {
  return new Promise(async (resolve) => {
    const token = AdminService.getToken()
    if (token) {
      try {
        const resp = await AdminService.getProfile(token)
        dispatch(adminProfileSuccess(resp));
        resolve();
      } catch (error) {
        dispatch(adminProfileError(error));
        resolve();
      }
    } else {
      resolve();
    }
  })
}

export const signIn = (data, history) => async dispatch => {
  dispatch(adminAuthRequest());
  try {
    const resp = await AdminService.signIn(data);
    message.success('เข้าสู่ระบบสำเร็จ')
    dispatch(adminAuthSuccess(resp));
    AdminService.setToken(resp);
    const param = new URLSearchParams(history.location.search);
    const path = param.get('path') || AppPath.admin.order;
    history.push(`${path}`);

  } catch (error) {
    dispatch(adminAuthError(error));
    message.error('username หรือ รหัสผ่านไม่ถูกต้อง')
  }
}