import { adminAuthTypes } from '../../actions/admin-auth/adminAuthTypes';

const initialState = {
  isAuthenticated: false,
  profile: {},
  level: 0,
  errors: [],
  isFetching: false,
}

const admins = (state = initialState, action) => {
  switch (action.type) {
    case adminAuthTypes.REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case adminAuthTypes.SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true
      }
    case adminAuthTypes.ERROR:
      return {
        ...state,
        isFetching: false,
        errors: action.errors
      }
    case adminAuthTypes.PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case adminAuthTypes.PROFILE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isFetching: false,
        profile: action.payload
      }
    case adminAuthTypes.PROFILE_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: action.errors
      }
    case adminAuthTypes.LOGOUT:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

export default admins