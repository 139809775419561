import React, { Component } from 'react'
import BaseLayout from '../components/layout/Layout'
import VerifyForm from '../components/product/VerifyPhone'
import ProductHome from '../components/product/ProductHome'


export default class Product extends Component {
  constructor(props) {
    super(props)
    this.state = { isVerify: false, telNumber: '' }
  }
  setVerify(telNumber) {
    this.setState({ telNumber: telNumber, isVerify: true })
  }

  setNotVerify() {
    this.setState({ isVerify: false })
  }
  render() {
    const { isVerify, telNumber } = this.state;
    return (
      <BaseLayout>
        {isVerify ?

          <ProductHome telNumber={telNumber} homeProduct={this.setNotVerify.bind(this)} />
          : <VerifyForm setVerify={this.setVerify.bind(this)} />
        }
      </BaseLayout>
    )
  }
}
