import React, { Component } from 'react'
import AdminBaseLayout from '../../components/layout/admin/AdminBaseLayout'
import AdminService from '../../services/AdminService'
import { Spin, message } from 'antd';
import Order from '../../components/admin/Order';

export default class AdminOrder extends Component {

  state = {
    order: {},
    isLoading: true
  }

  async componentDidMount() {
    const { match } = this.props;
    const id = match.params.id;
    try {
      const resp = await AdminService.getOrderById(id);
      this.setState({ order: resp, isLoading: false })
    } catch (error) {
      this.setState({ isLoading: false })
      if (error.status === 404) {
        message.error('ไม่พบข้อมูล')
      } else if (error.status === 401) {
        AdminService.logout();
      }
      console.log(error)
    }
  }

  setOrder(order) {
    this.setState({ order })
  }

  render() {
    const { order, isLoading } = this.state;
    return (
      <AdminBaseLayout >
        <div className="mt-3">
          {isLoading ? <Spin tip="Loading..." >
            <div style={{ minHeight: '80vh' }}></div>
          </Spin> : <Order order={order} setOrder={this.setOrder.bind(this)} />}
        </div>
      </AdminBaseLayout>
    )
  }
}
