import React, { Component } from 'react'
import AdminBaseLayout from '../../components/layout/admin/AdminBaseLayout'
import { PDFViewer } from '@react-pdf/renderer'
import { MyDocument } from '../../components/admin/mypdf/MyDocument'

export default class AdminHome extends Component {
  render() {
    return (
      <AdminBaseLayout>
        {/* <PDFViewer style={{ width: '100%', minHeight: '100vh' }}>
          <MyDocument data={[]} />
        </PDFViewer> */}
      </AdminBaseLayout>
    )
  }
}
